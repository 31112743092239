"use client";
import { useContext, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PortWidget from "./PortWidget";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import UpdateTwoToneIcon from "@mui/icons-material/UpdateTwoTone";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Chart } from "chart.js";
import data from "./example_datafile.json";
import dayjs from "dayjs";
import { at } from "lodash";
import networkJson from "./network.json";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const _ = require("lodash");

function ChartTitle(props: any) {
  let { label, value, size } = props;
  if (!size) {
    size = 6;
  }

  return (
    <Grid item sm={size} sx={{ padding: "1rem" }}>
      <Typography sx={{ color: "grey" }}>{label}</Typography>
      <Typography>
        {value}{" "}
        <ContentCopyIcon sx={{ position: "relative", marginLeft: "0.3rem" }} />
      </Typography>
    </Grid>
  );
}

export default function SiteSwitchPage(props: any) {
  const { params } = props;

  const actions = [
    {
      label: "Network test",
      icon: <NetworkCheckIcon fontSize="large" />,
    },
    {
      label: "Reboot",
      icon: <UpdateTwoToneIcon fontSize="large" />,
    },
    /*{
            label: "Deactivate",
            icon: <DeleteIcon fontSize="large" />
        },*/
    {
      label: "Admin",
      icon: <DisplaySettingsIcon fontSize="large" />,
      buttonText: "Open",
      buttonIcon: <OpenInNewIcon />,
    },
  ];

  useEffect(() => {
    const data = {
      labels: ["Download", "Upload"],
      datasets: [
        {
          label: "Dataset 1",
          data: [10, 20],
          backgroundColor: ["#99d699", "#ffd999"],
        },
      ],
    };

    const config: any = {
      type: "doughnut",
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Data Usage",
          },
        },
      },
    };
    const ctx: any = document.getElementById("dataUsageContainer");

    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, []);
  function bytesToMegabytes(bytes: number) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes;
  }
  useEffect(() => {
    const sorted_datapoints =
      data.networksOverview.historicalNetworksUsage.sort(
        (a, b) => a.sampleTime - b.sampleTime
      );
    const labels = sorted_datapoints.map((x: any) =>
      dayjs(new Date(x.sampleTime * 1000)).format("HH:MM")
    );

    const upstreamUsage = sorted_datapoints.map((data) =>
      bytesToMegabytes(
        data.wiredUpstreamDataTransferredInBytes +
          data.wirelessUpstreamDataTransferredInBytes
      )
    );
    const downstreamUsage = sorted_datapoints.map((data) =>
      bytesToMegabytes(
        data.wirelessDownstreamDataTransferredInBytes +
          data.wiredDownstreamDataTransferredInBytes
      )
    );

    const config: any = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Traffic Inbound",
            data: upstreamUsage,
            pointRadius: 0,
            fill: true,
            borderWidth: 1,

            borderColor: "rgba(115, 191, 105, 1)",
            backgroundColor: "rgba(115, 191, 105, 0.2)",
          },
          {
            label: "Traffic Outbound",
            data: downstreamUsage,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(228, 139, 74, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(228, 139, 74, 0.2)",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    };
    const ctx: any = document.getElementById("chartJSContainer2");

    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        style={{ padding: "1rem" }}
      >

        <Grid container style={{ marginTop: "1rem" }} spacing={2}>
          <Grid item sm={10} sx={{}}>
            <div style={{ backgroundColor: "white", height: "100%", padding:"0.5rem" }}>
              <canvas id="chartJSContainer2" width={"100%"} height="20" />
            </div>
          </Grid>
          <Grid item md={2}>
            <div style={{ backgroundColor: "white", height: "100%", padding:"0.5rem" }}>
              <canvas id="dataUsageContainer" />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <table style={{ textAlign: "center" }}>
                  <tr>
                    <td>Download</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>Upload</td>
                    <td>100</td>
                  </tr>
                </table>
              </div>
            </div>
          </Grid>
        </Grid>
        <PortWidget />
        <Grid container style={{ marginTop: "1rem" }} spacing={2}>
          <Grid item md={12}>
            <div
              style={{
                backgroundColor: "white",
                padding: "1rem",
                borderRadius: "0.3rem",
              }}
            >
              <Typography
                variant="h6"
                style={{ padding: "1rem 0rem 0rem 1rem" }}
              >
                Networks
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Network
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Health
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Status
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Type
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Clients
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>VLAN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {networkJson?.elements.map((row: any) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.wiredNetworkName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.health}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.isEnabled ? "Active" : "Disabled"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.isManagement ? "Management" : "Wired"}
                        </TableCell>
                        <TableCell>{row.wiredClientsCount}</TableCell>
                        <TableCell>{row.vlanId}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
