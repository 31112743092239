import {
  createBrowserRouter,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import { Sidebar } from "./components/Sidebar";
import Header from "./components/Header";
import DashboardPage, { dashboardLoader } from "./pages/dashboard/Dashboard";
import Api from "./api";
import { useContext, useEffect } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import UserContext, { UserProvider } from "./context/userContext";
import SiteDashboardPage, {
  siteDashboardLoader,
} from "./pages/site/dashboard/SiteDashboardPage";
import SiteLayout, { siteLoader } from "./pages/site/SiteLayout";
import SiteFirewallPage, {
  siteFirewallLoader,
} from "./pages/site/firewall/SiteFirewallPage";

import LocationWifi from "./pages/site/LocationWifi";
import LocationAlerts from "./pages/site/alerts/AlertsPage";
import LocationSettings from "./pages/site/settings/SiteSettingsPage";
import SwitchPage from "./pages/site/switch/SiteSwitchPage";
import OrderSitePage from "./pages/order_site/OrderSitePage";
import { Chart, registerables } from "chart.js";
import SiteContext from "./context/siteContext";
import ErrorIcon from "@mui/icons-material/Error";
import SiteFilesPage from "./pages/site/files/SiteFilesPage";
Chart.register(...registerables);

function Layout() {
  const navigate = useNavigate();
  const authData = useLoaderData();

  let location = useLocation();
  const {
    isLoading,
    setUserData,
    userData,
    snackbarText,
    setSnackbarText,
    setDashboardData,
  } = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => {
      setSnackbarText("");
    }, 2000);
  }, [setSnackbarText, snackbarText]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await Api.get("/auth");
        setUserData(res);
      } catch (e) {
        navigate("/login");
      }
    };
    if (userData == null) {
      getUser();
    }
  }, [navigate, setUserData, userData]);

  return (
    <>
      <Snackbar
        open={snackbarText !== ""}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={snackbarText}
      />
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Header />
      <Outlet />
    </>
  );
}

function ErrorFallback(e: any) {
  const navigate = useNavigate();
  let error: any = useRouteError();

  useEffect(() => {
    if (error?.status === 403) {
      navigate("/login");
    }
  }, [error?.status, navigate]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "90vh", textAlign: "center" }}
    >
      <Grid item>
        <ErrorIcon sx={{ fontSize: "4rem" }} />
        <Typography variant="h6">Something went wrong</Typography>
      </Grid>
    </Grid>
  );
}

export const router = createBrowserRouter([
  {
    element: <Login />,
    path: "login",
    errorElement: <ErrorFallback />,
  },
  {
    element: <Layout />,
    path: "",
    children: [
      {
        path: "",
        element: <DashboardPage />,
        loader: dashboardLoader,
        errorElement: <ErrorFallback />,
      },
      {
        path: "sites/:id",
        element: <SiteLayout />,
        loader: siteLoader,
        children: [
          {
            path: "dashboard",
            element: <SiteDashboardPage />,
            errorElement: <ErrorFallback />,
          },
          {
            path: "firewalls",
            element: <SiteFirewallPage />,
            errorElement: <ErrorFallback />,

            // loader: (request:any) => genericLocationLoader(request, "firewalls"),
          },
          {
            path: "switches",
            element: <SwitchPage />,
            errorElement: <ErrorFallback />,
            // loader: (request:any) => genericLocationLoader(request, "switches"),
          },
          {
            path: "wifi",
            element: <LocationWifi />,
            errorElement: <ErrorFallback />,
          },
          {
            path: "alerts",
            element: <LocationAlerts />,
            errorElement: <ErrorFallback />,
          },
          {
            path: "files",
            element: <SiteFilesPage />,
            errorElement: <ErrorFallback />,
          },
          {
            path: "settings",
            element: <LocationSettings />,
            errorElement: <ErrorFallback />,
          },
        ],
      },
      {
        path: "new",
        element: <OrderSitePage />,
      },
    ],
  },

  {
    path: "/*",
    element: <>Page not found</>,
  },
]);
