import { Box, Container, Grid } from "@mui/material";
import { Outlet, useLoaderData } from "react-router-dom";
import {
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import WifiIcon from '@mui/icons-material/Wifi';
import SecurityIcon from '@mui/icons-material/Security';
import InventoryIcon from '@mui/icons-material/Inventory';
import style from "./Site.module.css";
import Api from "../../api";
import { useContext, useEffect } from "react";
import SiteContext from "../../context/siteContext";
import AttachFileIcon from '@mui/icons-material/AttachFile';

export function siteLoader(request: any) {
  const { params } = request;
  return Api.get(`/location_dashboard/${params?.id}/`);
}

function getSidebarItems() {
  return [
    {
      title: "",
      links: [
        /*{
          title: "Dashboard",
          path: "dashboard",
          icon: <GridViewOutlinedIcon />,
          disabled:true,
        },
        {
          title: "Switch",
          path: "switches",
          icon: <InventoryIcon />,
          disabled:true,
        },*/
        {
          title: "Firewall",
          path: "firewalls",
          icon: <SecurityIcon />,
        },
        {
          title: "WiFi",
          path: "wifi",
          icon: <WifiIcon />,
        },
        {
          title: "Files",
          path: "files",
          icon: <AttachFileIcon />,
        },
        {
          title: "Alerts",
          path: "alerts",
          icon: <NotificationsActiveIcon />,
        },

        /*{
          title: "Settings",
          path: "settings",
          icon: <TuneIcon />,
        },*/
      ],
    },
  ];
}

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={style.sidebarWrapper}>
      {getSidebarItems().map((item, index) => {
        return (
          <List
            key={index}
            sx={{
              width: "100%",
              maxWidth: 360,
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {item.title}
              </ListSubheader>
            }
          >
            {item.links.map((link: any, index) => {
              return (
                <ListItemButton
                  key={index}
                  disabled={link.disabled}
                  onClick={() => navigate(link.path)}
                  sx={{backgroundColor:location?.pathname.endsWith(link.path) ? "#f5f5f5" : "white"}}
                >
                  <ListItemIcon>
                    {link.icon ? link.icon : <InboxIcon />}
                  </ListItemIcon>
                  <ListItemText primary={link.title} />
                </ListItemButton>
              );
            })}
          </List>
        );
      })}
    </div>
  );
}

export default function SiteLayout() {
  const data = useLoaderData();
  const {setDashboardData} = useContext(SiteContext);

  useEffect(() => {
    setDashboardData(data);
  }, [data]);

  return (
    <>
      <Box display="flex" >
        <Box
          width="12rem"
          className={style.layout_sidebar}
        >
          <Sidebar />
        </Box>
        <Box flexGrow={1} className={style.layout_content}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
