'use client'
import { Suspense } from 'react';
import { Container, Grid, Typography, Button, TextField, MenuItem, FormControl, Select, InputLabel, Alert } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';


interface IOrder {
    projectNr: string;
    projectName: string;
    invoiceCompany: string;
    invoiceNote: string;

    locationType: string;
    installationAddress: string;
    postAddress: string;
    bnrGnr: string;
    floorLevel: string;

    contactPersonName: string,
    contactPersonEmail: string,
    contactPersonPhone: string,
    projectStartDate: Date | string;
    projectEndDate: Date | string;
    approxPCUsers: string;
    bandwidth: string;
    additionalInfo: string;

}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function Order() {
    return (<Suspense fallback={<div>Loading...</div>}>
        <OrderHandler />
    </Suspense>);
}
function OrderHandler() {

    let order: IOrder = {
        projectNr: "",
        projectName: "",
        invoiceCompany: "",
        invoiceNote: "",
        locationType: "",
        installationAddress: "",
        postAddress: "",
        bnrGnr: "",
        floorLevel: "",
        contactPersonName: "",
        contactPersonEmail: "",
        contactPersonPhone: "",
        projectStartDate: new Date(),
        projectEndDate: new Date(),
        approxPCUsers: "",
        bandwidth: "",
        additionalInfo: ""

    }


    return (
        <Container>

            <Grid container justifyContent={"center"} sx={{ marginTop: "2rem" }}>
                <Grid item sm={9} sx={{ width: "100%", backgroundColor: "white", minHeight: "10rem", padding: "1rem" }}>
                    {<Alert severity="info" sx={{marginBottom:"2rem"}}>Order is under processing </Alert>}
                    <Typography variant="h5">Order Information</Typography>
                    Submit order for new location.
                    <Grid container>
                        <Grid item sm={12}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} sx={{ marginTop: "2rem" }}><Typography variant="h6">Invoice Information</Typography></Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField required id="outlined-basic" label="Project Nr" variant="standard" size="small" value={order.projectNr} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Project Name" variant="standard" size="small" value={order.projectName} />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="demo-simple-select-label">Invoice company</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={order.invoiceCompany}
                                            label="Age"
                                            onChange={() => { }}>
                                            <MenuItem value=""><em>None</em></MenuItem>
                                            <MenuItem value="1"><em>Test company</em></MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Invoice Note " variant="standard" size="small" />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} sx={{ marginTop: "2rem" }}><Typography variant="h6">Location Information</Typography></Grid>

                                <Grid item sm={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="demo-simple-select-label">Location Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={order.locationType}
                                            label="Age"
                                            onChange={() => { }}
                                        >
                                            <MenuItem value="1">Brakkerigg Modell 1</MenuItem>
                                            <MenuItem value="2">Brakkerigg Modell 2</MenuItem>
                                            <MenuItem value="3">Brakkerigg Modell 3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Installation Address" variant="standard" size="small" value={order.installationAddress} />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Post address for equipment" variant="standard" size="small" value={order.installationAddress} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={2}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="BNR/GNR" variant="standard" size="small" value={order.bnrGnr} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Floor/Level" variant="standard" size="small" value={order.floorLevel} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} sx={{ marginTop: "2rem" }}><Typography variant="h6">Contact Person</Typography></Grid>
                                <Grid item sm={4}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Name" variant="standard" size="small" value={order.contactPersonName} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Email" variant="standard" size="small" value={order.contactPersonEmail} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={4}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Phone" variant="standard" size="small" value={order.contactPersonPhone} />
                                    </FormControl>
                                </Grid>

                                <Grid item sm={12} sx={{ marginTop: "2rem" }}><Typography variant="h6">Project information</Typography></Grid>
                                
                                
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker slotProps={{ textField: { variant: "standard" } }} sx={{ marginTop: "0.8rem" }} defaultValue={dayjs(order.projectStartDate)} />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker slotProps={{ textField: { variant: "standard" } }} sx={{ marginTop: "0.8rem" }} defaultValue={dayjs(order.projectEndDate)} />
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth>
                                        <TextField id="outlined-basic" label="Approx. PC users" variant="standard" size="small" value={order.approxPCUsers} />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6}>
                                    <FormControl fullWidth variant="standard">
                                        <InputLabel id="demo-simple-select-label">Required bandwidth Mbps</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={order.bandwidth}
                                            label="Age"
                                            onChange={() => { }}

                                        >
                                            {["100/100", "200/200", "300/300", "500/500", "1000/1000"].map((mbs) => <MenuItem key={mbs} value={mbs}>{mbs}</MenuItem>)}

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="outlined-textarea"
                                            label="Additional Information"
                                            placeholder="Placeholder"
                                            multiline
                                            rows={1}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={10} sx={{ marginTop: "2rem" }}>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="text"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Add attachment
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                </Grid>
                                <Grid item sm={2} sx={{ marginTop: "2rem" }}>
                                    {<Button variant="contained">Submit</Button>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}