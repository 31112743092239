import {Container, Grid, Typography, Button} from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SiteContext from "../../context/siteContext";
import { useContext } from "react";

export default function LocationWifi(){
    const { dashboardData } = useContext(SiteContext);

    const url = dashboardData?.location?.wifi_admin_url;
    return (
        <Container maxWidth="xl">
        <Grid container>
            <Grid item sx={{width:"100%", backgroundColor:"white", minHeight:"10rem", padding:"1rem"}}>
                <Typography variant="h5">WiFi</Typography>
                <div style={{margin:"1rem 0 1rem 0"}}>WiFi is administred with a dedicated admin panel</div>
                <Button variant="contained" startIcon={<OpenInNewIcon />} disabled={url== null} onClick={() => window.open(url, '_blank')}>
                    Go to site
                </Button>
            </Grid>
        </Grid>
        </Container>
    )
}