import { Box, Container, Grid, LinearProgress, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import L from "leaflet";
import style from "./Dashboard.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Typography from "@mui/material/Typography";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import Api from "../../api";
import { IContactPerson, ISite } from "../../interface";
import { Chart } from "chart.js";
import SiteContext from "../../context/siteContext";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import dayjs from "dayjs";

export async function dashboardLoader() {
  return Api.get("/dashboard/");
}

function MiniChart(data: any) {
  useEffect(() => {
    const ctx: any = document.getElementById("mini_chart_" + data.count);
    const config: any = {
      type: "line",
      data: {
        labels: new Array(data.series.length).fill(0),
        datasets: [
          {
            data: data.series,
            pointRadius: 0,
            fill: false,
            borderWidth: 1.5,
            tension: 0.1,
          },
        ],
      },
      options: {
        hover: {
          mode: null  // Disable hover
        },
        animation: false,
        layout: {
          padding: 0,
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            min: 0,
            beginAtZero: true, // Ensures the y-axis starts at 0
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            padding: 0,
          },
          legend: {
            display: false, // This hides the legend
          },
          title: {
            display: false,
          },
        },
      },
    };
    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <Grid container sx={{ maxHeight: "5rem", maxWidth: "10rem" }}>
      <Grid item>
        <div>
          <canvas
            id={"mini_chart_" + data.count}
            style={{ height: "3.5rem" }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

function Footer() {
  const wrapper = {
    width: "100%",
    padding: "1rem 0 2rem 0",
    backgroundColor: "black",
    marginTop: "1rem",
  };
  return (
    <div style={wrapper}>
      <Container>
        <Grid
          container
          alignContent={"center"}
          justifyContent={"center"}
          spacing={2}
        >
          <Grid item>
            <div
              style={{
                color: "white",
                marginTop: "0.5rem",
                fontSize: "0.8rem",
              }}
            >
              POWERED BY
            </div>
          </Grid>
          <Grid item>
            <img src={"/static/it_logo.png"} height="30px" alt="itlogo"/>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function LocationDashboard() {
  const navigate = useNavigate();
  const data: any = useLoaderData();
  const [searchText, setSearchText] = useState("");
  const { setDashboardData } = useContext(SiteContext);

  useEffect(() => {
    setDashboardData(null);
  }, []);

  useEffect(() => {
    const dasbboardsettings = data.company.settings?.dashboard;

    const coords = dasbboardsettings?.map_coords ?? [65, 7.8933];
    const zoom = dasbboardsettings?.map_zoom ?? 4.4;

    const map = L.map("l_map", {
      attributionControl: false,
    }).setView(coords, zoom);
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(
      map
    );
    
    data.sites?.forEach((element: any) => {
      const lat = element.latitude;
      const long = element.longitude;
      if (lat && long) {
        L.circleMarker([lat, long], {
          color: element.is_operative ? "green" : "red",
          fillColor: element.is_operative ? "green" : "red",
          fillOpacity: 0.6,
          radius: 10,
        }).addTo(map);
      }
    });

    return () => {
      map.remove();
    };
  }, [data]);

  useEffect(() => {
    let site_count = data.sites.length;
    let active_sites = data.sites.filter((x: any) => x.is_operative).length;

    const chart_config = {
      labels: ["Operative", "Down"],
      datasets: [
        {
          label: "Service Status",
          data: [active_sites, site_count - active_sites],
          backgroundColor: ["rgb(201, 230, 192)", "rgb(250,160,160)"],
        },
      ],
    };
    const config: any = {
      type: "doughnut",
      data: chart_config,
      options: {
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
          y: {
            beginAtZero: true, // Ensures the y-axis starts at 0
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            border: {
              display: false,
            },
          },
        },
      },
    };

    const ctx: any = document.getElementById("operative_sites_chart");
    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <>
      <Container
        maxWidth="xl"
        style={{ marginTop: "1rem", minHeight: "calc(100vh - 10rem)" }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "1rem",
            borderRadius: "0.5rem",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Grid item md={12}>
            <Typography variant="h6" sx={{ pl: 0, pt: 0 }}>
              Network Dashboard
            </Typography>
          </Grid>

          <Grid item md={6}>
            <div className={style.tiles}>
              <Grid container alignItems={"center"}>
                <Grid item md={5}>
                  <Box sx={{ padding: "1rem" }}>
                    <canvas id="operative_sites_chart" width={"100%"} />
                  </Box>
                </Grid>
                <Grid item md={7} sx={{ height: "23" }}>
                  <Box sx={{ overflowY: "scroll", height: "23rem" }}>
                    <Timeline
                      sx={{
                        [`& .${timelineOppositeContentClasses.root}`]: {
                          flex: 0.2,
                        },
                      }}
                    >
                      {data.events.map((event: any) => {
                        let color = "primary" as any;
                        if (event.type === "w") {
                          color = "warning";
                        }
                        return (
                          <TimelineItem key={event.created}>
                            <TimelineOppositeContent color="text.secondary">
                              {dayjs(event.created).format("HH:mm MMM D")}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineDot color={color} />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Typography color="text.secondary">
                                {event.site.name}
                              </Typography>
                              {event.message}
                            </TimelineContent>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6}>
            <div className={style.tiles}>
              <div id="l_map" style={{ height: "23rem" }}></div>
            </div>
          </Grid>
        </Grid>

        <Grid container direction="column" spacing={2}>
          <Grid item sx={{ textAlign: "right" }}>
            <TextField
              id="standard-basic"
              label="Search"
              variant="standard"
              sx={{}}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Grid>
          <Grid item>
            <div style={{ backgroundColor: "white" }}>
              <table className={style.replies_table}>
                <thead>
                  <tr style={{ borderBottom: "1px lightgrey solid" }}>
                    <th style={{ width: "10px" }}>Status</th>
                    <th>Name</th>
                    <th>ISP</th>
                    <th>Responsible</th>
                    <th>Since last sync</th>
                    <th>Firewall Wan Traffic</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.sites?.map((row: ISite, i: number) => {
                    // const extra = row.status ? greenStatusColor : redStatusColor;
                    if (
                      searchText.length > 0 &&
                      !row.name.toLowerCase().includes(searchText.toLowerCase())
                    ) {
                      return;
                    }
                    let lastSync = "";
                    if (row.last_sync_datetime) {
                      const now = dayjs();
                      const pastTime = dayjs(row.last_sync_datetime);
                      let last_sync = ""
                      let sec = now.diff(pastTime, "second");
                      let minutes = now.diff(pastTime, "minutes");
                      let hours = now.diff(pastTime, "hours");

                      if(hours > 1){
                        lastSync = hours + " hours";
                      }else if(minutes > 1){
                        lastSync = minutes + " min";
                      }else {
                        lastSync = sec + " sec"
                      }
                      
                    }

                    return (
                      <tr
                        key={row.name}
                        style={{ border: 0, height: "5rem", cursor: "pointer" }}
                        onClick={() => navigate(`/sites/${row.id}/firewalls`)}
                      >
                        <td style={{ textAlign: "center" }}>
                          <RadioButtonCheckedIcon
                            sx={{ color: row.is_operative ? "green" : "red" }}
                          />
                        </td>
                        <td>{row.name}</td>
                        <td>{row.internet_provider.name}</td>
                        <td>
                          {row.contact_person
                            .map(
                              (person: IContactPerson) =>
                                person.first_name + " " + person.last_name
                            )
                            .join(", ")}
                        </td>
                        <td>{lastSync}</td>

                        <td>
                          <MiniChart
                            series={row.wan_traffic_firewall.map(
                              (x: any) => x.inpass
                            )}
                            count={i}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default function DashboardMain() {
  return <LocationDashboard />;
}
