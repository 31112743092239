import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Grid, Box, Typography, LinearProgress } from "@mui/material";
import Api from "../../../api";
import { IFirewall } from "../../../interface";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import dayjs from "dayjs";

export default function RunToolsModal(props: {
  show: boolean;
  setShow: any;
  firewall: IFirewall;
}) {
  const { show, setShow, firewall } = props;
  const [pendingTasks, setPendingTasks] = useState([]);
  const [openTaskIndex, setOpenTaskIndex] = useState(0);

  async function fetchPendingTasks() {
    const res = await Api.get(
      "/firewalls/tasks/?task=ping&firewall=" + firewall.serial_number
    );
    setPendingTasks(res.results);
  }

  useEffect(() => {
    fetchPendingTasks();
    const intervalId = setInterval(() => {
      if (show) {
        fetchPendingTasks();
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [firewall,show]);

  function selectTaskContent(task: any) {
    if (task.task_name === "ping") {
      if (task.status === "finished") {
        return (
          <div style={{ whiteSpace: "pre-line" }}>{task.results?.results}</div>
        );
      }
      return (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />

          <Typography variant="body2" sx={{textAlign:"center", pt:1}}>
            Retriving the results can be delayed due to batching. 
          </Typography>
        </Box>
      );
    }
    return "";
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());

    let data = {
      props: formJson,
      task_name: "ping",
      firewall: firewall.serial_number,
    };
    Api.post("/firewalls/tasks/", data);
    event.currentTarget.reset();
    fetchPendingTasks();
    setOpenTaskIndex(0);
  }

  return (
    <>
      <Dialog
        open={show}
        onClose={() => {
          setShow(false);
        }}
        PaperProps={{
          component: "form",
          onSubmit: onSubmit,
        }}
      >
        <DialogTitle>Run Ping</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                autoFocus
                fullWidth
                id="1"
                name="hostname"
                label="Hostname"
                type="text"
                variant="standard"
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                fullWidth
                id="2"
                name="attempts"
                label="Attempts"
                defaultValue={1}
                type="number"
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShow(false)}>Cancel</Button>
          <Button type="submit">Run</Button>
        </DialogActions>

        <hr />
        <Box sx={{ p: "1rem", borderTop: "1px lightGrey solid" }}>
          <Typography variant="body1">History</Typography>

          {pendingTasks.map((task: any, i) => (
            <Accordion key={task.id} expanded={i===openTaskIndex} onClick={() => {setOpenTaskIndex(i === openTaskIndex ? -1 : i)}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {dayjs(task.created).format("HH:mm - DD.MM.YY")} - {task.props.hostname}
              </AccordionSummary>
              <AccordionDetails>{selectTaskContent(task)}</AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Dialog>
    </>
  );
}
