// @ts-nocheck
import { Box, Tabs, Tab, Grid, Typography } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import style from "./portWidget.module.css";
import { useEffect, useState } from "react";
import { MetricTile } from "../firewall/SiteFirewallPage";
const _ = require("lodash");

const portNum = {
  backgroundColor: "black",
  fontSize: "0.7rem",
  color: "white",
  textAlign: "center",
  borderRadius: "4rem",
};

const status = {
  backgroundColor: "grey",
  height: "1.5rem",
  borderRadius: "0.5rem",
  margin: "0.2rem 0 0 0",
  padding: "0.2rem",
  textAlign: "center",
  color: "white",
  fontSize: "1.2rem",
};

const statusActive = {
  backgroundColor: "green",
  height: "1.5rem",
  borderRadius: "0.5rem",
  margin: "0.2rem 0 0 0",
  padding: "0.2rem 0.2rem 0.2rem 0.2rem",
  textAlign: "center",
  color: "white",
  fontSize: "1.2rem",
};
const statusUplink = {
  backgroundColor: "black",
  height: "2rem",
  borderRadius: "0.5rem",
  margin: "0.2rem 0 0 0",
};

const statusNetworkUnused = {
  backgroundColor: "white",
  border: "2px grey solid",
  height: "2rem",
  borderRadius: "0.5rem",
  margin: "0.2rem 0 0 0",
  padding: "0.2rem",
  textAlign: "center",
};

function PortHandler(props) {
  const { ports, type } = props;
  const [portsList, setPortsList] = useState(ports);

  function divideOddEven(numbers: any) {
    let oddPorts = [];
    let evenPorts = [];

    numbers.forEach((port) => {
      if (port.num % 2 === 0) {
        evenPorts.push(port);
      } else {
        oddPorts.push(port);
      }
    });

    return { oddPorts, evenPorts };
  }

  const { oddPorts, evenPorts } = divideOddEven(portsList);

  function handleClick(port) {
    let updatedList = portsList.map((portItt) => {
      if (portItt.num == port.num) {
        portItt.clicked = !portItt.clicked;
        return portItt;
      }
      portItt.clicked = false;
      return portItt;
    });
    setPortsList(updatedList);
  }
  function renderStatus(port) {
    if (type === 1) {
      if (port.state === 1) {
        return <div style={statusActive}></div>;
      }
      if (port.state === 2) {
        return <div style={statusUplink}></div>;
      }
    }
    if (type === 2) {
      if (port.state === 0) {
        return <div style={statusNetworkUnused}></div>;
      }
      if (port.state === 1) {
        return <div style={statusActive}>U</div>;
      }
      if (port.state === 2) {
        return <div style={status}>U</div>;
      }
    }

    return <div style={status}></div>;
  }

  return (
    <>
      <Grid container>
        {oddPorts.map((port: any) => (
          <Grid
            key={port.num}
            className={port.clicked ? style.clickedButton : style.wrapper}
            onClick={() => handleClick(port)}
          >
            <div style={portNum}>{port.num}</div>
            {renderStatus(port)}
          </Grid>
        ))}
      </Grid>
      <Grid container>
        {evenPorts.map((port: any) => (
          <Grid
            key={port.num}
            className={port.clicked ? style.clickedButton : style.wrapper}
            onClick={() => handleClick(port)}
          >
            <div style={portNum}>{port.num}</div>
            {renderStatus(port)}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default function PortWidget() {
  let ports = [];
  let networkPorts = [];

  for (let i = 0; i < 24; i++) {
    ports.push({
      num: i + 1,
      state: 0,
      clicked: false,
    });

    networkPorts.push({
      num: i + 1,
      state: 0,
      clicked: false,
    });
  }

  ports[0].clicked = true;
  ports[11].state = 1;
  ports[4].state = 1;
  ports[23].state = 2;

  networkPorts[0].clicked = true;
  networkPorts[0].state = 2;
  networkPorts[1].state = 2;
  networkPorts[2].state = 2;
  networkPorts[3].state = 2;
  networkPorts[5].state = 2;

  networkPorts[4].state = 1;

  networkPorts[11].state = 1;

  networkPorts[23].state = 1;

  const wrapperStyling = {
    borderRadius: "1rem",
    backgroundColor: "white",
    overflow: "hidden",
    padding: "1rem",
  };

  return (
    <Grid
      container
      style={{
        marginTop: "2rem",
        backgroundColor: "white",
        borderRadius: "0.5rem",
      }}
    >
      <Grid container alignItems={"center"} spacing={4} sx={{ padding: "1rem" }}>

        <Grid item>
        <FormControl fullWidth variant="standard" sx={{marginTop:"1rem", width:"100%"}}>
            <InputLabel id="demo-simple-select-label">Device name</InputLabel>
            <Select
              sx={{ width: "10rem" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={"2"}
              label="Firewall Name"
              onChange={(_firewall) => {
                console.log(_firewall);
              }}
            >
              <MenuItem key={1} value={"2"}>
                Test
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

      </Grid>
      <Grid item md={5}>
        <Grid container sx={{padding:"1rem"}}>
          <MetricTile key={1} label={"HOSTNAME"} value={"123"} />
          <MetricTile key={2} label={"HOSTNAME"} value={"321321"} />
          <MetricTile key={3} label={"HOSTNAME"} value={"41412"} />
          <MetricTile key={4} label={"HOSTNAME"} value={"41412"} />

        </Grid>
      </Grid>
      <Grid item md={7}>
        <div style={wrapperStyling}>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <div className={style.wrapper}>
                    <div style={statusActive}></div>
                  </div>
                </Grid>
                <Grid item sx={{ marginTop: "0.5rem" }}>
                  Active
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <div className={style.wrapper}>
                    <div style={statusUplink}>U</div>
                  </div>
                </Grid>
                <Grid item sx={{ marginTop: "0.5rem" }}>
                  Uplink
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <div className={style.wrapper}>
                    <div style={status}></div>
                  </div>
                </Grid>
                <Grid item sx={{ marginTop: "0.5rem" }}>
                  Unused
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <PortHandler ports={ports} type={1} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
