import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Api from "../../../api";
export function RebootModal(props: any) {

  const { show, setShow, firewall } = props;

  function handleRebootAction() {
    console.log("rebboot");
    let data = {
        props: {},
        task_name: "reboot",
        firewall: firewall.serial_number,
      };
      Api.post("/firewalls/tasks/", data);
      setShow(false);
  }

  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Reboot Firewall</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Rebooting the firewall will cause a temporary interruption in network
          connectivity. All active connections will be terminated, and it may
          take a few minutes for the system to fully restart and restore all
          services.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button onClick={handleRebootAction} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
