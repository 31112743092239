import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
} from "react-router-dom";
import UserContext, { UserProvider } from "./context/userContext";
import { LoadingCenterButton } from "./components/Loading";
import {router} from "./routes";
import { SiteProvider } from './context/siteContext';



      

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <UserProvider>
    <SiteProvider>
      <RouterProvider router={router} />
    </SiteProvider>
  </UserProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
