import React, { useState } from "react";
import _ from "lodash";

const SiteContext = React.createContext<any>({});

export const SiteProvider = (props: any) => {
    const { children } = props;
    const [dashboardData, setDashboardData] = useState<any>();

    return (
        <SiteContext.Provider
            value={{
                dashboardData,
                setDashboardData
            }}
        >
            {children}
        </SiteContext.Provider>
    );
};

export default SiteContext;
