import Api from "../../../api";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsInputCompositeIcon from "@mui/icons-material/SettingsInputComposite";
import style from "../Site.module.css";
import { useContext, useEffect, useState } from "react";
import SiteContext from "../../../context/siteContext";
import { IFirewall } from "../../../interface";
import dayjs from "dayjs";
import { Chart } from "chart.js";

export async function siteDashboardLoader(request: any) {
  return Api.get("/locations/");
}

const styleIconText = { color: "grey", marginBottom: "1rem" };

function DashboardClients(props: any) {
  return (
    <Box sx={{ height: 400, width: "100%", backgroundColor: "white" }}>
      <table className={style.replies_table}>
        <thead>
          <tr style={{ borderBottom: "1px lightgrey solid" }}>
            <th style={{ width: "10px" }}>Status</th>
            <th>Description</th>
            <th>Last seen</th>
            <th>Usage</th>
            <th>Client type</th>
            <th>IPv4 address</th>
          </tr>
        </thead>
        <tbody>
          {[].map((row: any) => {
            return (
              <tr key={row.name} style={{ border: 0, cursor: "pointer" }}>
                <td style={{ textAlign: "center" }}></td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
                <td>{row[4]}</td>
                <td>{row[5]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}

export default function SiteDashboardPage() {
  const { dashboardData } = useContext(SiteContext);
  const [activeFirewall, setFirewall] = useState<IFirewall>();

  let tiles = {
    uplinks: 0,
    firewall: 0,
    switches: 1,
  };

  useEffect(() => {
    const firewalls = dashboardData?.firewalls?.instances;
    if (firewalls?.length > 0) {
      setFirewall(firewalls[0]);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (activeFirewall == null) return;
    const labels = activeFirewall.traffic_data.map((x: any) =>
      dayjs(x.timestamp).format("HH:MM")
    );
    const inbound = activeFirewall.traffic_data.map((x: any) => x.inpass);
    const outbound = activeFirewall.traffic_data.map((x: any) => x.outpass);

    const config: any = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Traffic Inbound",
            data: inbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(115, 191, 105, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(115, 191, 105, 0.2)",
          },
          {
            label: "Traffic Outbound",
            data: outbound,
            pointRadius: 0,
            fill: true,
            borderColor: "rgba(228, 139, 74, 1)",
            borderWidth: 1,
            backgroundColor: "rgba(228, 139, 74, 0.2)",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
          },
        },
      },
    };
    const ctx: any = document.getElementById("traffic_data_chart");

    const chart = new Chart(ctx, config);
    return () => {
      chart.destroy();
    };
  }, [activeFirewall]);

  return (
    <Container maxWidth="xl">
      <Grid container direction={"column"} spacing={3}>
        <Grid item>
          <Grid container spacing={2} sx={{ marginTop: "0rem" }}>
            <Grid item xs={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={styleIconText}>UPLINKS</Typography>
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <span style={{}}>
                        <LanguageIcon fontSize="large" />
                      </span>
                    </Grid>
                    <Grid item>
                      <span style={{ fontSize: "2rem", marginLeft: "0.3rem" }}>
                        1
                      </span>
                    </Grid>
                    <Grid item>
                      <div style={{ margin: "0.4rem 0 0 0.1rem" }}>
                        / 1 healthy
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={styleIconText}>FIREWALL</Typography>
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <span style={{}}>
                        <SecurityIcon fontSize="large" />
                      </span>
                    </Grid>
                    <Grid item>
                      <span style={{ fontSize: "2rem", marginLeft: "0.4rem" }}>
                        1
                      </span>
                    </Grid>
                    <Grid item>
                      <div style={{ margin: "0.4rem 0 0 0.1rem" }}>
                        / 1 healthy
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography sx={styleIconText}>SWITCHES</Typography>
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <span style={{}}>
                        <SettingsInputCompositeIcon fontSize="large" />
                      </span>
                    </Grid>
                    <Grid item>
                      <span style={{ fontSize: "2rem", marginLeft: "0.5rem" }}>
                        {tiles.switches}
                      </span>
                    </Grid>
                    <Grid item>
                      <div style={{ margin: "0.4rem 0 0 0.1rem" }}>
                        / 1 healthy
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ backgroundColor: "white", p: 1 }}>
            <canvas id="traffic_data_chart" width={"100%"} height={15} />
          </Box>
        </Grid>
        <Grid item>
          <Grid item xs={12} sx={{ marginTop: "0rem" }}>
            <div style={{ backgroundColor: "white", padding: "0.3rem" }}>
              <Typography
                variant="h6"
                style={{ padding: "1rem 0rem 0rem 1rem" }}
              >
                Clients
              </Typography>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Interface
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Protocol
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Ip address
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Traffic</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Bytes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[].map((row: any) => (
                      <TableRow
                        key={row.mac}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.ip}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.mac}
                        </TableCell>
                        <TableCell>{row.hostname}</TableCell>
                        <TableCell>{row.start}</TableCell>
                        <TableCell>{row.end}</TableCell>
                        <TableCell>
                          {row.binding === "state" ? "active" : "free"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
