import * as React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-providers/leaflet-providers";

import { Grid, TextField } from "@mui/material";
import { useContext } from "react";
import SiteContext from "../../../context/siteContext";
const _ = require("lodash");

const styl = {
  borderRadius: "0.5rem",
  height: "100%",
  padding: "1rem",
  backgroundColor: "white",
  minHeight: "30rem",
};

export default function SiteSettingsPage(props: any) {
  const {dashboardData} = useContext(SiteContext);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <div style={styl}>
          <h2>Settings</h2>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <div>
                <h3 style={{ margin: "1rem 0 1rem 0" }}>General</h3>
                <TextField
                  variant="standard"
                  disabled
                  label="Name"
                  value={dashboardData?.location?.name}
                ></TextField>
                <div>
                  <TextField variant="standard" label="Address"></TextField>
                </div>
              </div>
              <h3 style={{ margin: "1rem 0 1rem 0" }}>Contact Person</h3>
              {dashboardData?.location?.contact_persons?.map((person:any) => 
              <Grid container spacing={2} key={person.id} sx={{marginBottom:"2rem"}}>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Name"
                    value={person.first_name + " " + person.last_name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Phonenumber"
                    value={person.phone_number}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="standard"
                    label="Email"
                    value={person.email}
                  />
                </Grid>
              </Grid>)}
            </Grid>

          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
