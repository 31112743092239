import { useContext, useEffect, useState } from "react";

import {
  Button,
  Grid,
  TextField,
Checkbox,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  InputLabel,
  Modal,
  Box,
  Typography,
  Container,
} from "@mui/material";

import style from "../Site.module.css";
import SiteContext from "../../../context/siteContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Api from "../../../api";
import _ from "lodash";
import { useParams, useRevalidator } from "react-router-dom";
import UserContext from "../../../context/userContext";

const textB = {
  width: "100%",
  margin: "0.5rem 0 0.5rem 0",
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditUserModal(props: any) {
  const { show, setShowModal, alerts, user, setUser } = props;
  const { setIsLoading } = useContext(UserContext);
  const revalidator = useRevalidator();

  async function createUser() {
    let url = "/users/"
    setIsLoading(true);

    if(user.id){
      await Api.put(`${url}${user.id}/`, user)
    }else {
      await Api.post(url, user)
    }
    setUser(empty_person);
    revalidator.revalidate();
    setIsLoading(false)
    setShowModal(false);
  }

  function handleChange(e:any){
    const cp = {...user};
    cp[e.target.name] = e.target.value;
    setUser(cp);
  }

  return (
    <Modal
      open={show}
      onClose={() => {setShowModal(false); setUser(empty_person)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 style={{ margin: "1rem 0 2rem 0" }}>Edit User</h2>
        <FormControl variant="standard" style={textB} onChange={handleChange}>
          <TextField
            name="first_name"
            variant="standard"
            label="Firstname"
            style={textB}
            value={user.first_name}
        ></TextField>
          <TextField
            name="last_name"
            variant="standard"
            label="Lastname"
            style={textB}
            value={user.last_name}
          ></TextField>
          <TextField disabled={user.id !== ""} name="email" variant="standard" label="Email" style={textB} value={user.email}/>
          <TextField name="phone_number"  variant="standard" label="Phone" style={textB} value={user.phone_number} />
        </FormControl>

        <Button variant="contained" style={textB} onClick={createUser}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}


function EditRules(props: any) {
  const { show, setShowModal, alerts, person } = props;

  return (
    <Modal
      open={show}
      onClose={() => {setShowModal(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 style={{ margin: "1rem 0 2rem 0" }}>Edit Rule</h2>
        <FormControl variant="standard" style={textB}>
          <InputLabel id="alert-type-select">Trigger</InputLabel>
          <Select
            labelId="alert-type-select"
            id="demo-simple-select"
            value={"lost_contact"}
            label="Channel"
            onChange={() => {}}
          >
            <MenuItem value={"lost_contact"}>Lost Contact</MenuItem>
          </Select>

        </FormControl>

        <FormControl variant="standard" style={textB}>
          <InputLabel id="alert-type-select">Channel</InputLabel>
          <Select
            labelId="alert-type-select"
            id="demo-simple-select"
            value={"Teams"}
            label="Channel"
            onChange={() => {}}
          >
            <MenuItem value={"Teams"}>Teams</MenuItem>
            <MenuItem value={"SMS"}>SMS</MenuItem>
            <MenuItem value={"EMAIL"}>Email</MenuItem>
          </Select>

        </FormControl>

        <Button variant="contained" style={textB}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}

const empty_person = {
  "id": "",
  "email": "",
  "phone_number": "",
  "first_name": "",
  "last_name": "",
}
export default function AlertsPage() {
  const { dashboardData } = useContext(SiteContext);
  const { setIsLoading } = useContext(UserContext);

  const params = useParams();
  const revalidator = useRevalidator();

  const { alerts } = dashboardData ?? {};
  const [showModal, setShowModal] = useState(false);
  const [showAlertModal, setShowRulesModal] = useState(false);
  const [user, setUser] = useState(empty_person);

  async function appointResponsible(user:any){
    setIsLoading(true);
    const res = await Api.post(`/sites/${params.id}/appoint/${user.id}/`)
    revalidator.revalidate();
    setIsLoading(false);
  }

  async function updateSMSAlert(is_selected:boolean, user:any) {
    const url = `/sites/${params.id}/alerts/${user.id}/`
    setIsLoading(true);
    if(is_selected){
      const res = await Api.delete(url, {})
    }else {
      const res = await Api.post(url, {})
    }
    revalidator.revalidate();
    setIsLoading(false);
  }



  return (
    <Container maxWidth="xl">
      <EditUserModal show={showModal} setShowModal={setShowModal} alerts={alerts} user={user} setUser={setUser} />
      <EditRules show={showAlertModal} setShowModal={setShowModal} alerts={alerts} user={user} />

      <div className={style.tile}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h6">Manage Alerts</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setShowModal(true)}>
              Add
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell align="center">Responsible</TableCell>
                <TableCell align="center">SMS Downtime Alert</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardData?.company?.users?.map((user: any) => {
                const has_sms_alert = user.alerts.filter((x:any) => x.type === "sms" && x.site === params.id).length === 1;
                return  <TableRow>
                    <TableCell>
                      {user.first_name + " " + user.last_name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone_number	}</TableCell>
                    <TableCell align="center">{ _.find(dashboardData.location?.contact_person, {id:user.id}) != null ? <CheckIcon /> : <Button onClick={() => appointResponsible(user)}>Appoint</Button>	}</TableCell>
                    <TableCell align="center">
                      <Checkbox checked={has_sms_alert} onClick={() => updateSMSAlert(has_sms_alert, user)}/>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setUser(user); 
                          setShowModal(true);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
              
            })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
}
