import React, { useState } from "react";
import { IUser } from "../interface";
import _ from "lodash";

const UserContext = React.createContext<any>({});

export const UserProvider = (props: any) => {
    const { children } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isAuth, setIsAuth] = useState(false);

    const [userData, setUser] = useState<IUser | null>();
    const [snackbarText, setSnackbarText] = useState("");

    const setUserData = (usrData?: IUser) => {
        if (usrData) {
            setUser(usrData);
            setIsAuth(true);
        }else {
            setIsAuth(false);
            setUser(null);
        }
        setIsLoading(false);
    };

    return (
        <UserContext.Provider
            value={{
                isLoading,
                setIsLoading,
                isAuth, 
                setIsAuth,
                setUserData,
                userData,
                snackbarText: snackbarText,
                setSnackbarText,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
